import { Helper, ApiClient } from "@zoox-framework/zoox-infra";
// import zCentralPayDatePicker from "../zCentralPayDatePicker";
// import FormMixin from "../../../services/mixins/FormMixin";
// import zCamera from "../zCamera";
// import zFlagNumber from "../zFlagNumber";
// import zPayConfirmationModal from "../zPayConfirmationModal";
import { FormMixin, zFlagNumber } from "@zoox-framework/zoox-components";
import Vue from "vue";
import zPayCameraModal from "@/components/zPayCameraModal";
import PersonService from "@/services/PersonService";
import zPayConfirmationModal from "@/components/zPayConfirmationModal";
import centralpayDatePicker from "@/components/shared/centralpayDatePicker";
import {
  DocumentTypeService,
  regionMixin,
  validationMixin,
  JobService,
  GeolocationService,
  CompanyService
} from "@zoox-framework/smck-service";

export default {
  name: "zPayPersonalDocForm",
  mixins: [regionMixin, validationMixin, FormMixin],
  components: {
    zPayConfirmationModal,
    centralpayDatePicker,
    zPayCameraModal: zPayCameraModal,
    "z-flag-number": zFlagNumber
  },
  props: {
    personData: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      personService: new PersonService(),
      jobService: new JobService(),
      geoLocationService: new GeolocationService(),
      companyService: new CompanyService(),
      apiClient: new ApiClient(),
      valid: true,
      searchNationality: null,
      searchResidenceCountry: null,
      searchZip: null,
      searchJob: null,
      camera: null,
      preventCombosFill: false,
      companyId: null,
      confirmationDialog: false,
      loading: false,
      finishRegister: false,
      openCamera: false,
      e1: 1,
      steps: 2,
      stepNames: [
        this.$t("COMPLETE_REGISTER.LABELS.PERSONAL_DATA"),
        this.$t("COMPLETE_REGISTER.LABELS.OTHER_DATA")
      ],
      person: {},
      residenceCountryItems: [],
      nationalityItems: [],
      jobItems: [],
      zipItems: [],
      genders: [
        { value: "M", name: this.$t("GLOBAL.GENDER_MALE") },
        { value: "F", name: this.$t("GLOBAL.GENDER_FEMALE") }
      ],
      personFields: [
        "name",
        "lastName",
        "email",
        "birthDate",
        "countryId",
        "countryName",
        "nationalityId",
        "nationalityName",
        "jobId",
        "jobName",
        "zipCode",
        "address",
        "addressNumber",
        "neighborhood",
        "addressComplement",
        "photoId",
        "documentTypeId",
        "documentNumber"
      ],
      companyParams: null,
      requiredDocuments: {
        cpf: true,
        passport: true
      },
      validateEmail: /\S+@\S+\.\S+/,
      validateEmailRule: [
        v => this.validateEmail.test(v) || this.$t("GLOBAL_ERROR.EMAIL")
      ],
      currentPersonEmail: "",
      openConflictModal: false,
      personCopy: {},
      message: ""
    };
  },
  async beforeMount() {
    await this.findCountry("", "residenceCountryItems");
    await this.findCountry("", "nationalityItems");
    await this.findJob("");
  },
  async created() {
    const company = await this.companyService.getLocalCompany();
    this.companyId = company.id;
    this.companyParams = await this.companyService.getFnrhFormSettings(
      company.id
    );
    const personId = this.personService.getPersonId();
    const person = await this.personService.get(personId);
    if (person) {
      this.person = await this.handlePersonData(person);
      await this.searchCountriesByZip(this.person.zipCode, true);
      this.personCopy = { ...this.person };
    }
    this.$forceUpdate();
  },
  computed: {
    continueButtonEnabled() {
      const fields = [
        "name",
        "lastName",
        "documentNumber",
        "ddiMobile",
        "dddMobile",
        "phoneNumberMobile",
        "email",
        "gender",
        "birthDate"
      ];

      const emptyFields = fields.filter(f => {
        return this.required(this.person[f]) === "Required";
      });

      return emptyFields.length === 0;
    },
    documentTypes: function() {
      const documents = new DocumentTypeService(Vue).getAll(
        this.requiredDocuments
      );

      if (this.person.documentTypeId >= 0) {
        let hasDocumentInList = false;

        for (let i = 0; i < documents.length; i++) {
          if (documents[i].value === this.person.documentTypeId)
            hasDocumentInList = true;
        }

        if (!hasDocumentInList) {
          this.person.documentTypeId = null;
          this.person.documentNumber = null;
        }
      }

      return documents;
    }
  },
  watch: {
    searchNationality: function(value) {
      if (!this.preventCombosFill) {
        this.findCountry(value, "nationalityItems");
      }
    },
    searchResidenceCountry: function(value) {
      if (!this.preventCombosFill) {
        this.findCountry(value, "residenceCountryItems");
      }
    },
    searchZip: function(value) {
      if (
        value &&
        ((value.includes("-") && value.length >= 9) || value.length >= 8) &&
        !this.preventCombosFill
      ) {
        const zipRegex = /[0-9]{5}-[\d]{3}/;

        let searchTerm = "";

        // Formata a busca para API

        if (zipRegex.test(value)) {
          searchTerm = value;
        } else {
          if (value.includes("-")) {
            searchTerm = value;
          } else {
            searchTerm = value.substr(0, 5) + "-" + value.substr(5, 10);
          }
        }

        this.searchCountriesByZip(searchTerm);
      } else if (this.zipItems.length !== 0) {
        // Faz nada
      } else {
        this.zipItems = [];
      }
    },
    searchJob: function(value) {
      this.findJob(value);
    }
  },
  methods: {
    ddiInputMobile(val) {
      this.person.ddiMobile = val.phoneDial;
      this.person.mobileCountryCode = val.phoneDial;
      this.person.mobilePhoneMask = val.mobilePhoneMask;
      this.person.mobilePhoneCountryCode = val.code;
    },
    normalizeCardEmail() {
      this.person.email = this.person.email.replace(/\s+/g, "").toLowerCase();
    },
    preventUpdateEmailandDocument() {
      if (
        this.person.email != this.personCopy.email &&
        this.person.documentNumber != this.personCopy.documentNumber
      ) {
        this.openConflictModal = true;
        this.message = "REGISTRATION.PREVENT_DOCUMENT_AND_EMAIL_UPDATE";
      } else {
        this.checkIfAlreadyExists();
      }
    },
    async checkIfAlreadyExists() {
      if (this.person.documentNumber != this.personCopy.documentNumber) {
        const exists = await this.apiClient.post(`person/check-person-exists`, {
          documentTypeId: this.person.documentTypeId,
          documentNumber: this.person.documentNumber,
          email: ""
        });
        if (exists === true) {
          this.openConflictModal = true;
          this.message = "REGISTRATION.DOCUMENT_CONFLICT";
        } else {
          this.save();
        }
      } else if (this.person.email != this.personCopy.email) {
        const exists = await this.apiClient.post(`person/check-person-exists`, {
          documentTypeId: "",
          documentNumber: "",
          email: this.person.email
        });
        if (exists === true) {
          this.openConflictModal = true;
          this.message = "REGISTRATION.EMAIL_CONFLICT";
        } else {
          this.save();
        }
      } else {
        this.save();
      }
    },

    onMobileMaskSelect(mask) {
      this.person.mobilePhoneMask = mask;
    },
    numberInputMobile(val) {
      const lastIndexDDDMobile = this.getLastIndexDdd(
        this.person.mobilePhoneMask
      );
      this.person.phoneNumberMobile = val;
      this.person.mobilePhoneNumber = val
        ? val.substring(lastIndexDDDMobile)
        : "";
      this.person.mobileCityCode = val
        ? val.substring(0, lastIndexDDDMobile)
        : "";
      this.person.dddMobile = val ? val.substring(0, lastIndexDDDMobile) : "";
    },
    async handlePersonData(person) {
      let personData = {};
      let providers = [];
      if (person.providers["zooxwifi"]) {
        providers.push(person.providers["zooxwifi"]);
      }
      if (person.providers["fnrh"]) {
        providers.push(person.providers["fnrh"]);
      }
      if (providers) {
        Object.keys(providers).forEach(k => {
          const provider = providers[k];
          if (provider.provider !== "zooxwifi" && provider.provider !== "fnrh")
            return;
          this.personFields.forEach(field => {
            personData[field] = personData[field]
              ? personData[field]
              : provider[field];
          });

          if (provider.gender) {
            personData.gender = this.convertGender(provider.gender);
          }

          personData.email = provider.email;
          // personData.cpf = provider.cpf;
          personData.mobilePhoneMask = provider.mobilePhoneMask;
          personData.countryId = personData.countryId
            ? personData.countryId
            : provider.countryId
            ? provider.countryId
            : provider.phoneCountryCode
            ? provider.phoneCountryCode
            : provider.mobileCountryCode;
          personData.phoneNumberMobile =
            provider.mobileCityCode && provider.mobilePhoneNumber
              ? provider.mobileCityCode + provider.mobilePhoneNumber
              : provider.phoneNumberMobile;
          personData.ddiMobile = personData.ddiMobile
            ? personData.ddiMobile
            : provider.ddiMobile || provider.mobileCountryCode;
          personData.dddMobile = personData.dddMobile
            ? personData.dddMobile
            : provider.dddMobile;
        });
      }

      if (person.providers.zooxwifi && person.providers.zooxwifi.photoId) {
        var parts = person.providers.zooxwifi.photoId.split("://");

        if (parts[parts.length - 1].indexOf("zooxpass.com") !== -1) {
          personData.photoId = "//" + parts[parts.length - 1];
        } else {
          personData.photoId = `${process.env.SMCK_BASE_URI}/open/person/${
            person.id
          }/photos/${parts[parts.length - 1]}`;
        }
      }
      return personData;
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    previousStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n - 1;
      }
    },
    async save() {
      if (this.$refs.profileForm.validate()) {
        const birthDate = this.person.birthDate;
        if (birthDate) {
          this.person.birthDate = new Date(birthDate).toISOString();
        }
        if (!this.person.addressComplement) {
          this.person.addressComplement = null;
        }
        this.person.companyId = this.companyId;
        this.person.image = this.person.photoId;
        this.$emit("save", this.person);
        this.personCopy = { ...this.person };
      }
    },
    back() {
      this.$emit("back");
    },
    convertGender(gender) {
      if (gender === 1) {
        return "F";
      } else if (gender === 2) {
        return "M";
      }
    },
    findCountry: function(value, propertyPersist) {
      //if (this.loading && !this.preventCombosFill) return;
      this.loading = true;

      return this.getCountries(value)
        .then(resp => {
          this[propertyPersist] = resp;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    findJob: function(value) {
      //if (this.loading && !this.preventCombosFill) return;
      this.loading = true;

      this.jobService
        .getAll(value)
        .then(resp => {
          this.jobItems = resp;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    residenceCountrySelect(data) {
      if (
        data &&
        data.name &&
        data.code &&
        this.person.countryId !== data.code
      ) {
        this.person.countryName = data.name;
        this.person.countryId = data.code;
      }
    },
    nationalitySelect(data) {
      if (data && data.name && data.code) {
        this.person.nationalityName = data.name;
        this.person.nationalityId = data.code;
      }
    },
    jobSelect(data) {
      if (data && data.name && data._id) {
        this.person.jobName = data.name;
        this.person.jobId = data._id;
      } else if (typeof data === "string") {
        this.person.jobId = null;
      }
    },
    filterItems(item, queryText, itemText) {
      if (queryText === null || queryText.length === 0) {
        return true;
      }
      return (
        Helper.normalizeString(itemText).indexOf(
          Helper.normalizeString(queryText)
        ) > -1
      );
    },
    async onCapture(image) {
      this.person.photoId = image;
      this.openCamera = false;
    },
    onCameraError() {
      this.stopCamera();
    },
    stopCamera() {
      this.openCamera = false;
    },
    searchCountriesByZip: function(filter = "", skipLoading = false) {
      const $context = this;

      if (
        !filter ||
        (!skipLoading && $context.loading && !this.preventCombosFill)
      )
        return;
      $context.loading = true;

      return $context.geoLocationService
        .geocodeAddress(
          filter,
          $context.person.countryId,
          $context.currentLang || ($context.$i18n && $context.$i18n.locale)
        )
        .then(resp => {
          if (resp.length === 0 && filter.length > 5) {
            $context.searchCountriesByZip(filter.substr(0, 5), true);
          } else {
            $context.zipItems = resp;
          }
        })
        .catch(err => {
          console.error(err);
          $context.zipItems = [];
        })
        .finally(() => {
          $context.loading = false;
        });
    },
    addZip() {
      if (this.person.zipCode !== this.searchZip) {
        this.person.addressNumber = "";
        this.person.addressComplement = "";

        this.person.address = "";
        this.person.neighborhood = "";
        this.person.addressComplement = "";
        this.person.zipCode = "";

        this.person.zipCode = this.searchZip;

        this.zipItems = this.zipItems.concat({
          zipCode: this.searchZip,
          value: this.searchZip
        });
      }
    },
    selectZip(data) {
      delete this.person.addressNumber;
      delete this.person.addressComplement;
      if (data) {
        if (data.country == "Brasil") {
          this.person.countryId = "BR";
        }
        this.person.zipCode = data.zipCode;
        this.person.address = data.route;
        this.person.neighborhood = data.neighborhood;

        if (data.complement) {
          this.person.addressComplement = data.complement;
        }
      }
    },
    getDocumentMask: function(documentType) {
      switch (documentType) {
        case 1: // CPF
          return "###.###.###-##";
        case 5: // RUT
          return "#######-N";
        default:
          return "";
      }
    },
    getDocumentLength: function(documentType) {
      switch (documentType) {
        case 4: // Passport
          return 12;
        default:
          return 20;
      }
    },
    getDocumentValidation: function(documentType, value) {
      let isValid = true;

      isValid = isValid && this.rules.required(value);

      switch (documentType) {
        case 1: // CPF
          isValid = isValid && this.rules.cpf(value);
          break;
        case 5: // RUT
          isValid = isValid && this.rules.rut(value);
          break;
        default:
          break;
      }

      return isValid;
    },
    getLastIndexDdd(mask) {
      /* eslint-disable no-useless-escape */
      mask = mask || "";
      const maskDdd = mask
        ? mask
            .replace(/[\(|\)]/g, "")
            .replace(/[-|\.|\/]/g, " ")
            .split(" ")[0]
        : "";
      if (mask.length === maskDdd.length) {
        return 0;
      }
      return maskDdd.length >= 4 ? maskDdd.length - 2 : maskDdd.length;
    }
  }
};
