import Vue from "vue";
import PersonService from "@/services/PersonService";
import CardService from "@/services/CardService";
import { regionMixin, validationMixin } from "@zoox-framework/smck-service";
import zPayConfirmationModal from "@/components/zPayConfirmationModal";
import zPayViewPaymentMethods from "@/components/zPayViewPaymentMethods";
import zPayPersonalDocForm from "@/components/zPayPersonalDocForm";
// import {
//   zPayConfirmationModal,
//   zPayViewPaymentMethods,
//   zPayPersonalDocForm
// } from "@zoox-framework/zoox-components";
import Create from "../Card/Create/Create.vue";

export default {
  mixins: [regionMixin, validationMixin],
  name: "Profile",
  components: {
    zPayPersonalDocForm,
    zPayViewPaymentMethods,
    zPayConfirmationModal,
    Create
  },
  data() {
    return {
      vueInstance: Vue,
      person: null,
      tab: 0,
      step: 0,
      cards: [],
      showLoading: false,
      confirmationDialog: false,
      personService: new PersonService(),
      cardService: new CardService()
    };
  },
  async created() {
    const personId = this.personService.getPersonId();
    this.person = await this.personService.get(personId);
    await this.listCards();
    this.$forceUpdate();
  },
  methods: {
    addCard() {
      this.$router.push("/card/create");
    },
    callBack() {
      this.$router.back();
    },
    async callSave(person) {
      if (person.addressComplement === null) {
        person.addressComplement = " ";
      }

      let personSemPhoto = { ...person };
      delete personSemPhoto.photoId;
      delete personSemPhoto.image;
      const [respFRNH, respWifi] = await Promise.all([
        this.personService.update(personSemPhoto),
        this.personService.update(person, "zooxwifi")
      ]);
      if (respFRNH && respWifi) {
        this.confirmationDialog = true;
      }
    },
    async listCards() {
      await this.cardService
        .getCards()
        .then(resp => {
          this.cards = resp;
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    async confirmDelete(cardId) {
      await this.cardService.deleteCard(cardId).then(async () => {
        this.deleteClicked = false;
        await this.listCards();
      });
    },
    async showConfirmationModal() {
      this.step = 0;
      await this.listCards();
      this.confirmationDialog = true;
    }
  }
};
