import moment from "moment";
import uuid from "uuid";

export default {
  name: "centralpayDatePicker",
  props: [
    "id",
    "data",
    "label",
    "placeholder",
    "name",
    "value",
    "type",
    "width",
    "format",
    "mask",
    "locale",
    "rules",
    "min",
    "max"
  ],
  data() {
    return {
      modal: false,
      menu: false,
      date: null,
      dateToShow: null,
      hasError: false
    };
  },
  computed: {
    computedDateFormatted: function() {
      let date = this.dateToShow || this.date || this.value;

      if (this.format && !this.dateToShow) {
        if (this.type !== "dialog") {
          this.$emit("input", date);
        }

        return date
          ? moment(date)
              .utc()
              .format(this.format)
          : "";
      } else if (this.format && this.dateToShow) {
        date = this.value;
        return date ? moment(date, "YYYY-MM-DD").format(this.format) : "";
      } else {
        return date;
      }
    }
  },
  methods: {
    openDatePicker: function() {
      this.modal = this.menu = true;
    },
    changeDate: function(evt) {
      const $context = this;
      if (evt) {
        const date = moment(evt, $context.format);
        if (
          date.isValid() &&
          $context.allowedDates(date.format("YYYY-MM-DD"))
        ) {
          $context.dateToShow = evt;
          $context.$emit("input", date.format("YYYY-MM-DD"));
          $context.$emit("change", date.format("YYYY-MM-DD"));
        } else {
          $context.$refs.textDatePicker.reset();

          setTimeout(() => {
            // IPC(*): Isso irá gerá uma reclamação no cliente em desenvolvimento
            $context.$refs.textDatePicker.$set(
              $context.$refs.textDatePicker,
              "error",
              true
            );
          }, 30);
        }
      }
    },
    allowedDates: function(val) {
      const date = moment(val);
      if (this.min && this.max) {
        const minDate = moment(this.min);
        const maxDate = moment(this.max);

        return date.isBetween(minDate, maxDate);
      } else {
        // validar se data nao é menor ou superior a 100 anos
        return (
          date.year() >=
            moment()
              .subtract(100, "years")
              .year() &&
          date.year() <=
            moment()
              .add(100, "years")
              .year()
        );
      }
    },
    close: function() {
      this.menu = false;
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
    submit: function() {
      this.$refs.dialog.save(this.date);
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
    cancel: function() {
      this.modal = false;
      this.$emit("cancel");
    },
    closeDatePicker: function() {
      this.$nextTick(() => this.$refs.textDatePicker.focus());
      this.menu = false;
    },
    onFocus: function(evt) {
      const uniqueId = uuid.v4();
      evt.target.setAttribute("autocomplete", uniqueId);
      evt.target.name = uniqueId;
    }
  }
};
