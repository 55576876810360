import zPayDeleteCardConfirmModal from "@/components/zPayDeleteCardConfirmModal";
export default {
  name: "zPayViewPaymentMethods",
  props: {
    cards: {
      type: Array,
      default: []
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    zPayDeleteCardConfirmModal
  },
  data() {
    return {
      dialogExpenseVisible: false,
      loadingButton: false,
      deleteClicked: false,
      selectedCard: null,
      key: false
    };
  },
  computed: {
    enablePayButton() {
      return this.selectedCards.length > 0 && this.totalPayment === this.value;
    }
  },
  methods: {
    back() {
      this.step = 0;
      this.$emit("back");
    },
    getFlag: function(brand) {
      const flag = brand || "";
      return require(`@/assets/images/${flag.toLowerCase()}.png`);
    },
    allowsInstallmentPayment: function(card) {
      let allowed = false;
      if (card.acquirer && card.acquirer.acceptInstallments) {
        allowed = true;
      }
      return allowed;
    },
    deleteCard(card) {
      this.selectedCard = card;
      this.key = !this.key;
      this.deleteClicked = true;
    },
    confirmDelete(cardId) {
      this.$emit("deleteCard", cardId);
      this.deleteClicked = false;
    }
  }
};
